<template>
  <div class="py-4 flex justify-between justify-items-center w-full border-b lg:border-b-1 border-gray-200 dark:border-gray-800 px-8">
    <div id="mobile_menu" class="flex md:hidden justify-items-center"></div>
    <UBreadcrumb
      class="self-center hidden lg:block"
      divider="/"
      :links="links"
    />
    <UDropdown v-if="store.info?.id" :items="items" :popper="{ placement: 'bottom-start' }" class="block">
      <div class="flex items-center">
        <UAvatar :alt="name" />
        <span class="px-2 hidden md:block">{{ name }}</span>
      </div>

      <template #account="{ item }">
        <div class="text-left">
          <p>
            {{ $t('EMAIL') }}
          </p>
          <p class="truncate font-medium text-gray-900 dark:text-white">
            {{ item.label }}
          </p>
        </div>
      </template>

      <template #item="{ item }">
        <span class="truncate">{{ item.label }}</span>
        <UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto" />
      </template>
    </UDropdown>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';

const { t } = useI18n();
const route = useRoute();
const { replace } = useRouter();
const { $sdk } = useNuxtApp();
const store = useUserStore();
const { name, info } = storeToRefs(store);


const items = computed(() => [
  [{
    label: info.value?.email || '',
    slot: 'account',
    disabled: true
  }],
  [{
    label: t('SETTINGS'),
    icon: 'i-heroicons-cog-8-tooth',
    click: () => replace('/settings/profile')
  }],
  [{
    label: t('SIGN_OUT'),
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: signOut,
  }]
]);

const links = computed(() => {

  const routes: Array<{ label: string, to?: string; }> = [{ label: t('HOME'), to: '/' }];

  const routeNames = {
    '/users': t('USERS'),
    '/immobiles': t('IMMOBILES'),
    '/customers': t('CUSTOMERS'),
    '/settings/profile': t('PROFILE'),
    '/settings/password': t('PASSWORD'),
    '/settings/preferences': t('PREFERENCES'),
    '/reports': t('REPORTS'),
    '/reports/360-view': t('REPORTS'),
  };

  if (route.path.match('/settings')) {
    routes.push({ label: t('SETTINGS') });
  }

   routes.push({ label: routeNames[route.path as keyof typeof routeNames] });

  return routes;
  
}); 

function signOut() {
  $sdk.auth.signOut();

  replace({
    path: '/sign_in',
    replace: true
  });
}
</script>
